<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Loans
        </h3>
        <span class="subtitle">
          You have submitted {{ loansThisMonth }} loans this month
        </span>
      </div>
      <div class="page-header-buttons">
        <button type="button" class="btn bg-green" @click="modals.newOrigination = true">
          New Origination
        </button>
      </div>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': page === 'loans' }">
          <router-link :to="{ name: 'loans' }">
            In Process
          </router-link>
        </li>
        <li :class="{ 'active': page === 'loan_closing' }">
          <router-link :to="{ name: 'loan_closing' }">
            Closing
          </router-link>
        </li>
        <li :class="{ 'active': page === 'loan_funded' }">
          <router-link :to="{ name: 'loan_funded' }">
            Funded
          </router-link>
        </li>
        <li :class="{ 'active': page === 'loan_declined' }">
          <router-link :to="{ name: 'loan_declined' }">
            Declined
          </router-link>
        </li>
      </ul>
    </div>
    <div class="page-content p-normal">
      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField">
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
      <div class="text-center mt-4" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
          <tr>
            <th>
              Borrower
            </th>
            <th>
              Agents
            </th>
            <th>
              Lender
            </th>
            <th>
              Documents
            </th>
            <th>
              Application & Notes
            </th>
            <th>
              Task
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="loan in tableSearch" :key="loan.id">
            <td>
              {{loan.borrowerName}}
            </td>
            <td>
              <div class="agents-avatars">
                <img v-for="a in loan.agents" :key="a.id" :src="agentPhoto(a.photo)" :alt="a.name">
              </div>
            </td>
            <td>
              {{loan.lender}}
              <img v-if="loan.lockStatus === 'expiring5d'"
                   src="@/assets/icons/locked expiring 5 days.svg" alt="">
              <img v-if="loan.lockStatus === 'locked'"
                   src="@/assets/icons/locked active.svg" alt="">
              <img v-if="loan.lockStatus === 'unlocked'"
                   src="@/assets/icons/unlocked.svg" alt="">
              <img v-if="loan.lockStatus === 'expiring2d'"
                   src="@/assets/icons/locked expiring 2 days.svg" alt="">
            </td>
            <td>
              <div class="d-flex align-items-center">
                <button class="btn bg-white" @click="modals.documents = true; modals.loanId = loan.id;">
                  Documents
                </button>
                <img v-if="loan.docsRequested > 0 && loan.docsRequested <= loan.docsReceived"
                     src="@/assets/icons/Received.svg"
                     class="ms-2" alt=""
                     content="Docs Received"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }">
                <div class="received-docs" v-else-if="loan.docsReceived > 0"
                     content="New Docs"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }">
                  {{loan.docsReceived}}
                </div>
                <img v-else-if="loan.docsRequested > 0 && loan.docsRequested > loan.docsReceived"
                     content="Docs Pending"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }"
                     src="@/assets/icons/Pending.svg"
                     class="ms-2" alt="">
                <img v-else-if="loan.docsRequested === 0"
                     content="No Docs"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }"
                     src="@/assets/icons/No Requested Docs.svg"
                     class="ms-2" alt="">
              </div>

            </td>
            <td>
              <div class="d-flex align-items-center">
                <button class="btn bg-white" @click="modals.loanApp = true; modals.loanId = loan.id">
                  Application
                </button>
                <button class="btn btn-control ms-2"
                        @click="modals.editOrigination = true; modals.loanId = loan.id"
                        content="Edit Application"
                        v-tippy="{ placement : 'bottom', arrow : true, arrowType : 'round' }">
                  <img src="@/assets/icons/icon-edit-normal.svg" alt="">
                </button>
                <button class="btn btn-control ms-2"
                        content="Show Notes"
                        @click="modals.note = true; loanId = loan.id"
                        v-tippy="{ placement : 'bottom', arrow : true, theme : 'dark', arrowType : 'round' }">
                  <img src="@/assets/icons/icon-notes.svg" alt="">
                </button>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center" :name="'showTasks' + loan.id" parent>
                <template v-for="task in loan.tasks">
                  <img src="@/assets/icons/Task Status Checked Preview.svg" v-if="task.isCompleted" alt="" :key="task.id">
                  <div v-else class="task-nocomplete" :key="task.id"></div>
                </template>
              </div>
              <tippy
                :to="'showTasks' + loan.id"
                theme="light"
                maxWidth="200"
                placement="bottom-center">
                <div class="tasks-list">
                  <div class="d-flex justify-content-start">
                  <span class="taskList-title">
                    Task
                  </span>
                  </div>
                  <div v-for="task in loan.tasks"  :key="task.id">
                    <div class="d-flex align-items-center" v-if="task.isCompleted">
                      <img src="@/assets/icons/Task Status Checked Preview.svg"  alt="" >
                      <span class="task-title">
                      {{ task.title }}
                    </span>
                    </div>
                    <div class="d-flex align-items-center" v-else >
                      <div class="task-nocomplete"></div>
                      <span class="task-title">
                      {{ task.title }}
                    </span>
                    </div>
                  </div>
                </div>
              </tippy>
            </td>
            <td>
            <td>
              <button :name="'borrowerAction' + loan.id"
                      class="btn btn-control"
                      parent>
                <img src="@/assets/icons/icon-more.svg" alt="">
              </button>
              <tippy :to="'borrowerAction' + loan.id"
                     trigger="click"
                     arrowType="round"
                     boundary="window"
                     interactive="true"
                     animateFill="false"
                     placement="bottom-end"
                     zIndex="99" hideOnClick="true" theme="light"
                     sticky="true" class="borrower-action">
                <div class="borrower-action-block">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-login.svg" alt="">
                        Login to Lender Account
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-email.svg" alt="">
                        Email to Rep
                      </a>
                    </li>
                    <li class="action-label">
                      Process
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-appraisal.svg" alt="">
                        Request Appraisal
                      </a>
                    </li>
                    <li @click="modals.escrowDetails = true; modals.loanId = loan.id;" class="cursor-pointer">

                      <img src="@/assets/icons/icon-escrow.svg" alt="">
                      Escrow

                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-underwriting.svg" alt="">
                        Underwriting
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-closing.svg" alt="">
                        Closing
                      </a>
                    </li>
                    <li class="action-label">
                      Actions
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-moveto.svg" alt="">
                        Move to Funding Request
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-sync.svg" alt="">
                        Sync
                      </a>
                    </li>
                    <li v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BRANCH_MANAGER')">
                      <a href="#" @click="modals.loanReassign = true; modals.loanId = loan.id">
                        <img src="@/assets/icons/icon-profile.svg" alt="">
                        Reassign Loan
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="modals.loanDecline = true; modals.loanId = loan.id">
                        <img src="@/assets/icons/icon-archive.svg" alt="">
                        Decline
                      </a>
                    </li>
                  </ul>

                </div>
              </tippy>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <NewOriginationModal
        @close="modals.newOrigination = false"
        @loanCreated="modals.newOrigination = false; ui.LoansTable.listing.isLoading = true; loadLoans();"
        v-if="modals.newOrigination"></NewOriginationModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <EditOriginationModal
        :loanId="modals.loanId"
        @close="modals.editOrigination = false"
        @loanUpdated="loadLoans"
        v-if="modals.editOrigination"></EditOriginationModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <SelectFromModal
        @close="modals.selectFromModal = false"
        v-if="modals.selectFromModal"></SelectFromModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanAppModal
        :loan-id="modals.loanId"
        @editApplication="modals.editOrigination = true"
        @close="modals.loanApp = false"
        v-if="modals.loanApp"></LoanAppModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <DocumentsModal
        @close="modals.documents = false"
        :loanId="modals.loanId"
        @docsRequested="loadLoans"
        v-if="modals.documents"></DocumentsModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <EscrowModal
        :loanId="modals.loanId"
        @editLoan="editLoan($event)"
        @close="modals.escrowDetails = false"
        v-if="modals.escrowDetails"></EscrowModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <NotesModal
        :loanId="loanId"
        @close="modals.note = false"
        v-if="modals.note"
        @openTask="openTaskModal($event)"></NotesModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <TaskDetailsModal
        :taskId="taskId"
        @close="modals.taskDetails = false"
        v-if="modals.taskDetails">
      </TaskDetailsModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanReassignModal
        :loanId="modals.loanId"
        @close="modals.loanReassign = false"
        @load-loans="loadLoans"
        v-if="modals.loanReassign" >
      </LoanReassignModal>
    </transition>

    <transition name="component-fade" mode="out-in">
      <LoanDeclineModal
        :loanId="modals.loanId"
        @close="modals.loanDecline = false"
        @load-loans="loadLoans"
        v-if="modals.loanDecline" >
      </LoanDeclineModal>
    </transition>
  </div>
</template>

<script>
import NewOriginationModal from "./NewOriginationModal";
import EditOriginationModal from "./EditOriginationModal";
import SelectFromModal from "./SelectFromModal";
import DocumentsModal from "./DocumentsModal";
import LoanAppModal from "./LoanAppModal";
import EscrowModal from "./escrow/EscrowModal";
import NotesModal from "./NotesModal";
import TaskDetailsModal from "./TaskDetailsModal";
import LoanReassignModal from "./LoanReassignModal";
import LoanDeclineModal from "./LoanDeclineModal";

export default {
  name: 'LoansFunded',
  components: {
    NewOriginationModal, EditOriginationModal,
    SelectFromModal, LoanAppModal, DocumentsModal, EscrowModal,
    NotesModal, TaskDetailsModal, LoanReassignModal, LoanDeclineModal},
  data() {
    return {
      loansThisMonth: 0,
      searchField: '',
      modals: {
        loanId: 0,
        newOrigination: false,
        editOrigination: false,
        selectFromModal: false,
        newOriginationSelected: false,
        loanApp: false,
        documents: false,
        escrowDetails: false,
        editLoan: false,
        note: false,
        taskDetails: false,
        loanReassign: false,
        loanDecline: false
      },
      ui: {
        loading: false
      },
      loans: {
        data: [],
        error: false,
        errorMessage: '',
      },
      paging: []
    }
  },
  methods: {
    loadLoans() {
      this.ui.loading = true;
      this.$http.get('/api/v1/loans',
        {
          params: {
            offset: this.paging.offset,
            limit: this.paging.limit,
          }
        }).then((res) => {
        this.loans.data = res.data.data
        this.paging = res.data.paging
        this.ui.loading = false;
        res.data.data.map((object) => {
          object.openDropDown = false
        });
      }).catch((err) => {
        this.loans.error = true;
        this.loans.errorMessage = err.response.data.error
      })
    },
  },
  beforeMount() {
    this.loadLoans()
  },
  computed: {
    page() {
      return this.$route.name
    },
    tableSearch() {
      return this.loans.data.filter(loan => {
        return loan.borrowerName.toLowerCase().includes(this.searchField.toLowerCase())
      })
    },
  },
}
</script>
<style lang="scss" scoped>
table {
  tbody {
    td {
      vertical-align: middle;
    }
  }
  &.table-progress {
    thead {
      th {
        vertical-align: middle;
        &:first-child {
          width: 15%;
        }
        &:nth-child(n + 2) {
          text-align: center;
          width: 10%;
        }
      }
    }
    tbody {
      td {
        height: 60px;
        &:first-child {
          width: 15%;
          border-right: 1px solid #E7E8E8;
          border-bottom: 1px solid #E7E8E8;

        }
        &:nth-child(n + 2) {
          text-align: center;
          width: 10%;
          border-right: 1px solid #E7E8E8;
          border-bottom: 1px solid #E7E8E8;
        }
      }
    }
  }
  &.closing-table {
    thead {
      th {
        vertical-align: middle;
        &:first-child {
          width: 15%;
        }
        &:nth-child(n + 2):nth-child(-n+3) {
          width: 13%;
        }
        &:nth-child(n + 3) {
          width: 10%;
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td {
          height: 60px;
          vertical-align: middle;
          &:first-child {
            width: 15%;
            border-bottom: 1px solid #E7E8E8;
          }
          &:nth-child(n + 2):nth-child(-n+3) {
            width: 13%;
            border-bottom: 1px solid #E7E8E8;
          }
          &:nth-child(3) {
            border-right: 1px solid #E7E8E8;
            border-bottom: 1px solid #E7E8E8;
          }
          &:nth-child(n + 3) {
            width: 10%;
            text-align: center;
            border-right: 1px solid #E7E8E8;
            border-bottom: 1px solid #E7E8E8;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

.agents-avatars {
  img {
    height: 34px;
    width: 34px;
    border: 2px solid #F6F6F6;
    border-radius: 50%;
    &:nth-child(n + 2) {
      margin-left: -5px;
    }
  }
}

.borrower-action-block {
  background: white!important;
  ul {
    padding: 12px 0;
    list-style: none;
    li {
      text-align: initial;
      padding: 4px 11px;
      &:hover {
        background-color: rgba(0,0,0,.05);
      }
      a {
        color: rgba(0,0,0,1);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        text-decoration: none;
      }

    }
  }
}

.action-label {
  opacity: 0.5;
  color: rgba(0,0,0,1);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 12px;
}

</style>
